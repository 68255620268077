/* global Word, Office, OfficeExtension */

import { findAllIndices, setTotalProgressInBulk } from './addInMethods';
import {
  DISTANCES_FOUND_PERCENT_THRESHOLD,
  fetchDistances,
} from './apiHelpers';
import { testErrorType } from './localStorageHelper';
import { StatusManager, PromiseStatus, StatusCodes } from './models';

Office.onReady(() => {
  // If need to do smth after start Add-in
});

const REGULAR_FONT_COLOR: string = 'black';
const REFERENCE_FONT_COLOR: string = 'blue';
// const REFERENCE_FONT_UNDERLINE = Word.UnderlineType.none;
const ERROR_FONT_COLOR: string = 'orange';
// const ERROR_FONT_UNDERLINE = Word.UnderlineType.none;

const getDocumentId = async () => {
  function uuidv4() {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }

  let id: string = '';
  await Word.run(async (context) => {
    const customDocProperties = context.document.properties.customProperties;
    let customProperty: Word.CustomProperty;
    try {
      customProperty = customDocProperties.getItem('Id');
      customProperty.load(['key', 'value']);
      await context.sync();

      console.log('Custom key  : ' + customProperty.key);
      console.log('Custom value : ' + customProperty.value);
      id = customProperty.value;
    } catch (err) {
      console.log('Custom property Id not found');

      id = uuidv4();
      customDocProperties.add('Id', id);
      await context.sync();

      const newCustomProperty = customDocProperties.getItem('Id');
      newCustomProperty.load(['key', 'value']);
      await context.sync();
      console.log('New Custom key  : ' + newCustomProperty.key);
      console.log('New Custom value : ' + newCustomProperty.value);
    }
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });

  return id;
};

const getDocumentLanguage = async () => {
  let language: string = '';
  await Word.run(async (context) => {
    const customDocProperties = context.document.properties.customProperties;
    let customProperty: Word.CustomProperty;
    try {
      customProperty = customDocProperties.getItem('PatentLang');
      customProperty.load(['key', 'value']);
      await context.sync();

      console.log('Custom key  : ' + customProperty.key);
      console.log('Custom value : ' + customProperty.value);
      language = customProperty.value;
    } catch (err) {
      console.log('Custom property PatentLang not found');
    }
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });

  return language;
};

const setDocumentLanguage = async (language: string) => {
  await Word.run(async (context) => {
    const customDocProperties = context.document.properties.customProperties;

    customDocProperties.add('PatentLang', language);
    await context.sync();

    const newCustomProperty = customDocProperties.getItem('PatentLang');
    newCustomProperty.load(['key', 'value']);
    await context.sync();
    console.log('New Custom key  : ' + newCustomProperty.key);
    console.log('New Custom value : ' + newCustomProperty.value);
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });
};

const updateDocumentWithContent = async (documentContent: string) => {
  await Word.run(async (context) => {
    const body = context.document.body;

    // Clear the content of the body
    body.clear();

    if (documentContent) {
      // Insert the template content into the new document
      body.insertFileFromBase64(documentContent, Word.InsertLocation.end);
    }

    context
      .sync()
      .then(function () {
        console.log('Document opened');
      })
      .catch(function (e) {
        console.log(e);
      });
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });
};

const modifyOoxml = (ooxml, keyword, bookmarkId) => {
  // This regular expression finds the <w:t> tags containing the specified keyword
  const regex = new RegExp(
    `(<w:r[^>]*>(?:(?!<\/w:r>).)*<w:t[^>]*>([^<]*?)(${keyword})([^<]*?)<\/w:t>(?:(?!<\/w:r>).)*<\/w:r>)`,
    'gis'
  );

  let match;
  while ((match = regex.exec(ooxml))) {
    console.log('Full <w:r> tag containing the keyword:', match[1]);
    console.log(
      'Captured <w:t> tag containing the keyword:',
      match[0].match(/<w:t[^>]*>.*?<\/w:t>/gis)[0]
    );
    console.log('Keyword captured:', match[3]);

    // Assuming 'fullWRTag' is the string output from the first regex match that includes the <w:r> tag
    const fullWRTag = match[1];

    // Regex to capture the entire <w:rPr> tag and its contents
    const rPrRegex = /<w:rPr>([\s\S]*?)<\/w:rPr>/;

    // Extract the <w:rPr> tag
    const rPrMatch = rPrRegex.exec(fullWRTag);

    if (rPrMatch) {
      console.log('Captured <w:rPr> tag and its contents:', rPrMatch[1]);
    } else {
      console.log('No <w:rPr> tag found within the provided <w:r> tag.');
    }
  }

  // Replace function to add formatting and bookmarks only around the keyword
  const replacement = (
    match,
    startTag,
    preText,
    keywordText,
    postText,
    endTag
  ) => {
    const ret =
      `${startTag}${preText}</w:t></w:r>` +
      `<w:r><w:bookmarkStart w:id="${bookmarkId}" w:name="${bookmarkId}"/><w:rPr><w:color w:val="FF0000"/></w:rPr><w:t>${keywordText}</w:t><w:bookmarkEnd w:id="${bookmarkId}"/></w:r>` +
      `<w:r><w:t>${postText}${endTag}`;
    console.log(match);
    return ret;
  };

  // Apply the regex and return the modified OOXML
  const ret1 = ooxml.replace(regex, replacement);

  return ret1;
};

const getMultiBlocksParagraphs = async (blockNames: string[]) => {
  const result: Word.Paragraph[] = [];
  await insertLineBreakBlockParagraphs();
  await Word.run(async (context) => {
    // Create a proxy object for the document body.
    const body = context.document.body;

    // Load the paragraphs from the document
    body.load('paragraphs');
    await context.sync();

    const allParagraphs: Word.Paragraph[] = [];
    // Iterate through each paragraph
    for (let i = 0; i < body.paragraphs.items.length; i++) {
      const paragraph = body.paragraphs.items[i];

      // Get the text of the current paragraph
      paragraph.load('text');
      paragraph.load('uniqueLocalId');

      await context.sync();
      allParagraphs.push(paragraph);
    }

    for (let i = 0; i < blockNames.length; i++) {
      const paragraphs = await collectBlockParagraphs(
        allParagraphs,
        blockNames[i]
      );
      result.push(...paragraphs);
    }
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });

  return result;
};

const getMultiBlocksParagraphsWithProgress = async (
  blockNames: string[],
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  minProgress: number,
  maxProgress: number
) => {
  const multiBlocksParagraphs: Word.Paragraph[] = [];

  await insertLineBreakBlockParagraphs();
  await Word.run(async (context) => {
    // Create a proxy object for the document body.
    const body = context.document.body;

    // Load the paragraphs from the document
    body.load('paragraphs');
    await context.sync();

    const allParagraphs: Word.Paragraph[] = [];
    // Iterate through each paragraph
    for (let i = 0; i < body.paragraphs.items.length; i++) {
      const paragraph = body.paragraphs.items[i];

      // Get the text of the current paragraph
      paragraph.load('text');
      paragraph.load('uniqueLocalId');

      await context.sync();
      allParagraphs.push(paragraph);

      const progress =
        (i / (body.paragraphs.items.length - 1)) *
          (maxProgress - minProgress * 0.0001) +
        minProgress;
      setTotalProgressInBulk(promiseStatus, progress);
      statusManager.setProgress(promiseStatus.progress);
      statusManager.setStatusCode(StatusCodes.InProgress);
    }

    for (let i = 0; i < blockNames.length; i++) {
      const paragraphs = await collectBlockParagraphs(
        allParagraphs,
        blockNames[i]
      );
      multiBlocksParagraphs.push(...paragraphs);
    }
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });

  return multiBlocksParagraphs;
};

const collectBlockParagraphs = (
  allParagraphs: Word.Paragraph[],
  blockName: string
) => {
  const result: Word.Paragraph[] = [];
  let blockContent = false;
  for (let i = 0; i < allParagraphs.length; i++) {
    const paragraph = allParagraphs[i];
    if (
      paragraph.text &&
      paragraph.text.includes('---') &&
      paragraph.text.includes(blockName)
    ) {
      blockContent = !paragraph.text.includes('/' + blockName);
    } else if (blockContent) {
      result.push(paragraph);
    }
  }
  return result;
};

const insertBlockParagraphs = async (
  blockName: string,
  strings: string[],
  splitStrings: boolean,
  beginning: boolean,
  cleanup: boolean,
  comments: string[] = []
) => {
  await Word.run(async (context) => {
    // Create a proxy object for the document body.
    const body = context.document.body;

    // Load the paragraphs from the document
    body.load('paragraphs');
    await context.sync();

    let tagParagraph;//: Word.Paragraph;
    let blockContent = false;

    // Iterate through each paragraph
    for (let i = 0; i < body.paragraphs.items.length; i++) {
      const paragraph = body.paragraphs.items[i];

      // Get the text of the current paragraph
      paragraph.load('text');
      //console.log('text:' + paragraph.text);
      await context.sync();

      if (
        paragraph.text &&
        paragraph.text.includes('---') &&
        paragraph.text.includes(blockName)
      ) {
        blockContent = !paragraph.text.includes('/' + blockName);
        if (paragraph.text.includes('/' + blockName) === !beginning) {
          tagParagraph = paragraph;
        }
      } else if (cleanup && blockContent) {
        paragraph.delete();
      }
    }

    for (let s = 0; s < strings.length; s++) {
      const sIdx = beginning ? strings.length - s - 1 : s;
      const lines = splitStrings
        ? splitToLines([strings[sIdx]])
        : [strings[sIdx]];

      let range: Word.Range;
      for (let l = 0; l < lines.length; l++) {
        const lIdx = beginning ? lines.length - l - 1 : l;
        if (lines[lIdx]) {
          const par = tagParagraph.insertParagraph(
            lines[lIdx],
            beginning ? Word.InsertLocation.after : Word.InsertLocation.before
          );

          if (blockName == 'figure_descriptions') {
            // Set hanging indent to 2 cm
            par.leftIndent = 57; // This value is equivalent to 2 cm in points
            par.firstLineIndent = -57; // Negative for hanging indent, same as left indent for a full hang
          }

          // Apply Arial, size 12, black color to each paragraph
          par.font.name = 'Arial';
          par.font.color = 'black';
          par.font.size = 12;

          if (blockName == 'title' && beginning) {
            par.alignment = Word.Alignment.centered;
            par.font.bold = true;
          } else {
            // Set justification
            par.alignment = Word.Alignment.justified;
          }

          if (!range) {
            range = par.getRange(Word.RangeLocation.content);
          } else {
            range = range.expandTo(par.getRange(Word.RangeLocation.content));
          }
        }
      }
      if (comments && s < comments.length) {
        let sIdx = s;
        if (beginning) {
          sIdx = strings.length - s - 1;
        }
        range.insertComment(comments[sIdx]);
      }
    }

    if (tagParagraph) {
      // Use the Word API's scrollIntoView method for the paragraph
      tagParagraph.getRange(Word.RangeLocation.start).scrollIntoView({ behavior: "smooth"});
    }

    await context.sync();
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });
};

const insertLineBreakBlockParagraphs = async () => {
  await Word.run(async (context) => {
    var paragraphs = context.document.body.paragraphs;
    paragraphs.load('items');

    await context.sync();

    for (let p = 0; p < paragraphs.items.length; p++) {
      const paragraph = paragraphs.items[p];
      if (paragraph) {
        paragraph.load('text');
        await context.sync();
        const text = paragraph.text;

        const charRanges = paragraph.search('?', { matchWildcards: true });
        charRanges.load();
        await context.sync();

        const startTags = findAllIndices(text, '<---');
        startTags.forEach((startTag) => {
          if (startTag > 0) {
            charRanges.items[startTag - 1].insertText(
              '\n',
              Word.InsertLocation.after
            );
          }
        });

        const endTags = findAllIndices(text, '--->');
        endTags.forEach((endTag) => {
          if (endTag > -1 && endTag + 3 < text.length - 1) {
            charRanges.items[endTag + 3].insertText(
              '\n',
              Word.InsertLocation.after
            );
          }
        });
      }

      await context.sync();
    }
  }).catch(function (error) {
    console.log('Error: ' + error);
  });
};

const deleteBlockParagraphs = async (blockName: string) => {
  await Word.run(async (context) => {
    // Create a proxy object for the document body.
    const body = context.document.body;

    // Load the paragraphs from the document
    body.load('paragraphs');
    await context.sync();

    let blockContent = false;

    // Iterate through each paragraph
    for (let i = 0; i < body.paragraphs.items.length; i++) {
      const paragraph = body.paragraphs.items[i];

      // Get the text of the current paragraph
      paragraph.load('text');
      //console.log('text:' + paragraph.text);
      await context.sync();

      if (
        paragraph.text &&
        paragraph.text.includes('---') &&
        paragraph.text.includes(blockName)
      ) {
        blockContent = !paragraph.text.includes('/' + blockName);
      } else if (blockContent) {
        paragraph.delete();
      }
    }

    await context.sync();
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });
};

const deleteBlocksMarkupParagraphs = async () => {
  await Word.run(async (context) => {
    // Create a proxy object for the document body.
    const body = context.document.body;

    // Load the paragraphs from the document
    body.load('paragraphs');
    await context.sync();

    // Iterate through each paragraph
    for (let i = 0; i < body.paragraphs.items.length; i++) {
      const paragraph = body.paragraphs.items[i];

      // Get the text of the current paragraph
      paragraph.load('text');
      //console.log('text:' + paragraph.text);
      await context.sync();

      if (paragraph.text && paragraph.text.includes('<---')) {
        paragraph.delete();
      }
    }

    await context.sync();
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });
};

const splitToLines = (lines: string[]) => {
  let result: string[] = [];
  for (let i = 0; i < lines.length; i++) {
    const subLines = lines[i].replace(/\n$/, '').split('\n');
    for (let j = 0; j < subLines.length; j++) {
      result.push(subLines[j]);
    }
  }

  return result;
};

const findParagraphByText = async (main: string) => {
  let texts: string[] = [];
  let ids: string[] = [];

  await Word.run(async (context) => {
    await context.sync();
    // Create a proxy object for the document body.
    const body = context.document.body;

    // Load the paragraphs from the document
    body.load('paragraphs');

    await context.sync();

    // Iterate through each paragraph
    for (let i = 0; i < body.paragraphs.items.length; i++) {
      const paragraph = body.paragraphs.items[i];

      // Get the text of the current paragraph
      paragraph.load('uniqueLocalId');
      paragraph.load('text');
      await context.sync();

      ids.push(paragraph.uniqueLocalId);
      texts.push(paragraph.text);
    }

    // Run the final context sync
    await context.sync();
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });

  let parId = '';

  const distancesResp = await fetchDistances(main, texts);
  if (!distancesResp.error) {
    const max = Math.max(...distancesResp.distances);

    if (max > DISTANCES_FOUND_PERCENT_THRESHOLD) {
      const index = distancesResp.percentage.indexOf(max);
      console.log(index);
      if (index >= 0) {
        parId = ids[index];
      }
    }
  }

  return parId;
};

const removeParagraphById = async (id: string) => {
  let result = false;
  await Word.run(async (context) => {
    let paragraph = context.document.getParagraphByUniqueLocalId(id);
    if (paragraph) {
      paragraph.delete();
      await context.sync();
      result = true;
    }
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });
  return result;
};

const removeParagraphByText = async (main: string) => {
  let result = false;
  const parId = await findParagraphByText(main);
  if (parId) {
    result = await removeParagraphById(parId);
  }
  return result;
};

const insertTermDescriptionAfterText = async (main, description) => {
  const parId = await findParagraphByText(main);
  let result = false;
  if (parId) {
    await Word.run(async (context) => {
      await context.sync();
      let paragraph = context.document.getParagraphByUniqueLocalId(parId);
      if (paragraph) {
        paragraph.insertParagraph(description, Word.InsertLocation.after);
        await context.sync();
        result = true;
      }
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  }
  return result;
};

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getRandomHexId = () => {
  const rndNum = getRandomInt(1, 65535);
  const hexString = rndNum.toString(16).padStart(4, '0');
  return hexString;
};

const insertParagraphAfterParId = async (parId: string, text: string) => {
  let result = false;
  if (parId) {
    await Word.run(async (context) => {
      await context.sync();

      let paragraph = context.document.getParagraphByUniqueLocalId(parId);
      if (paragraph) {
        paragraph.insertParagraph(text, Word.InsertLocation.after);
        await context.sync();
        result = true;
      }
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  }
  return result;
};

const updateParagraphTextByParId = async (parId: string, text: string) => {
  let result = false;
  if (parId) {
    await Word.run(async (context) => {
      await context.sync();
      text = text.replaceAll(/(\r\n|\n|\r)/gm, '');

      let paragraph = context.document.getParagraphByUniqueLocalId(parId);
      if (paragraph) {
        paragraph.insertText(text, Word.InsertLocation.replace);
        await context.sync();
        result = true;
      }
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  }
  return result;
};

const insertParagraphAfterParagraph = async (
  parentParagraph: Word.Paragraph,
  text: string
) => {
  let result = false;
  if (parentParagraph) {
    await Word.run(async (context) => {
      parentParagraph.insertParagraph(text, Word.InsertLocation.after);
      await context.sync();
      result = true;
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  }
  return result;
};

const deleteInlineReferencesMultipleBlocks = async (
  blockNames: string[],
  replace: string
) => {
  for (let i = 0; i < blockNames.length; i++) {
    await deleteInlineReferences(blockNames[i], replace);
  }
};

const deleteInlineReferences = async (blockName: string, replace: string) => {
  await Word.run(async (context) => {
    // Create a proxy object for the document body.
    const body = context.document.body;

    // Load the paragraphs from the document
    body.load('paragraphs');
    await context.sync();

    let blockContent = false;

    // Iterate through each paragraph
    for (let i = 0; i < body.paragraphs.items.length; i++) {
      const paragraph = body.paragraphs.items[i];

      // Get the text of the current paragraph
      paragraph.load('text');
      //console.log('text:' + paragraph.text);
      await context.sync();

      if (
        paragraph.text &&
        paragraph.text.includes('---') &&
        paragraph.text.includes(blockName)
      ) {
        blockContent = !paragraph.text.includes('/' + blockName);
      } else if (blockContent) {
        let parText = paragraph.text;
        const regex = /<%(.*?)%>/gm;
        parText = parText.replaceAll(regex, replace);
        const regex2 = /<#E(.*?)#>/gm;
        parText = parText.replaceAll(regex2, '');
        const regex3 = /<\[(.*?)\]>/gm;
        parText = parText.replaceAll(regex3, replace);
        //paragraph.insertText(parText, Word.InsertLocation.replace);
      }
    }

    await context.sync();
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });
};

const insertErrorMarkerIntoParId = async (parId: string, position: number) => {
  let result = '';
  const hexString = getRandomHexId();

  if (parId) {
    await Word.run(async (context) => {
      await context.sync();
      let paragraph = context.document.getParagraphByUniqueLocalId(parId);
      if (paragraph) {
        paragraph.load('text');
        await context.sync();
        let parText = paragraph.text;
        parText =
          parText.substring(0, position) +
          '<#E-' +
          //'<b>' +
          hexString +
          //'</b>' +
          '#>' +
          parText.substring(position);
        paragraph.insertText(parText, Word.InsertLocation.replace);
        //paragraph.insertHtml(parText, Word.InsertLocation.replace);
        await context.sync();

        result = hexString;
      }
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  }
  return result;
};

const getErrorIdFromParId = async (parId: string, position: number) => {
  let result = '';

  if (parId) {
    await Word.run(async (context) => {
      await context.sync();
      let paragraph = context.document.getParagraphByUniqueLocalId(parId);
      if (paragraph) {
        paragraph.load('text');
        await context.sync();
        let parText = paragraph.text;
        result = getErrorIdFromText(parText, position);
      }
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  }
  return result;
};

const getErrorIdFromText = (text: string, position: number) => {
  let result = '';
  const markerLength = 10;
  const regexp = /<#E-(.*?)#>/gm;
  if (position < text.length) {
    const subText = text.substring(position);
    const matches = [...subText.matchAll(regexp)];
    if (matches.length > 0) {
      if (matches[0].index < markerLength) {
        // length of marker
        result = matches[0][1];
      }
    }
  }

  return result;
};

const getGptErrorFromText = (
  text: string,
  position: number,
  recalcWithoutTags: boolean
) => {
  let result: RegExpMatchArray[] = [];
  const regexp = /<\[(.*?)\]>/gm;
  if (position < text.length) {
    const subText = text.substring(position);
    const matches = [...subText.matchAll(regexp)];
    if (matches.length > 0) {
      if (recalcWithoutTags) {
        let delta = 0;
        for (let m = 0; m < matches.length; m++) {
          matches[m].index -= delta;
          delta += 4; // shift index left by 4 chars tag.
          result.push(matches[m]);
        }
      } else {
        result = matches;
      }
    }
  }

  return result;
};

const getBookmarkErrorIdFromParId = async (
  parId: string,
  start: number,
  end: number
) => {
  let errorId = '';

  if (parId) {
    await Word.run(async (context) => {
      await context.sync();
      let paragraph = context.document.getParagraphByUniqueLocalId(parId);
      if (paragraph) {
        const charRanges = paragraph.search('?', { matchWildcards: true });
        charRanges.load();
        await context.sync();

        const targetRange = charRanges.items[start].expandTo(
          charRanges.items[end]
        );
        targetRange.load();
        await context.sync();

        const hyperLinks = targetRange.getHyperlinkRanges();
        hyperLinks.load('hyperlink,text');
        await context.sync();

        // Log each hyperlink.
        hyperLinks.items.forEach((linkRange) => {
          errorId = extractErrorIdFromHyperlink(linkRange);
        });
      }
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  }
  return errorId;
};

const insertBookmarkErrorIntoParId = async (
  parId: string,
  insertDummy: boolean,
  start: number,
  end: number
) => {
  let errorId = '';
  const hexString = getRandomHexId();

  if (parId) {
    await Word.run(async (context) => {
      await context.sync();
      let paragraph = context.document.getParagraphByUniqueLocalId(parId);
      if (paragraph) {
        const charRanges = paragraph.search('?', { matchWildcards: true });
        charRanges.load();
        await context.sync();

        let targetRange: Word.Range;
        if (insertDummy) {
          const spaceRange = charRanges.items[end].insertText(
            ' ',
            Word.InsertLocation.after
          );
          spaceRange.load();
          await context.sync();
          targetRange = spaceRange.insertText('(!)', Word.InsertLocation.after);
          console.log('start:' + start);
          console.log('end:' + end);
        } else {
          targetRange = charRanges.items[start].expandTo(charRanges.items[end]);
        }

        targetRange.load();
        await context.sync();

        console.log('target range error text:"' + targetRange.text + '"');

        const hyperLinks = targetRange.getHyperlinkRanges();
        hyperLinks.load('hyperlink,text');
        await context.sync();

        // Log each hyperlink.
        hyperLinks.items.forEach((linkRange) => {
          errorId = extractErrorIdFromHyperlink(linkRange);
        });
        if (!errorId) {
          // No error found. Add new bookmark
          errorId = hexString;
          const bookmarkName = '_E_' + errorId;

          targetRange.insertBookmark(bookmarkName);
          targetRange.hyperlink = '#' + bookmarkName;
          targetRange.font.color = ERROR_FONT_COLOR;
          targetRange.font.underline = Word.UnderlineType.none;

          await context.sync();
        }
      }
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  }
  return errorId;
};

const extractErrorIdFromHyperlink = (linkRange: Word.Range) => {
  let hyperlink = linkRange.hyperlink;
  let errorId = '';
  console.log(
    'body hyperlink:"' + hyperlink + '", text:"' + linkRange.text + '"'
  );
  if (hyperlink.length > 0) {
    hyperlink = hyperlink.trim().replaceAll('bookmark://', '');
    hyperlink = hyperlink.replaceAll('#', '');
    if (hyperlink.startsWith('_E')) {
      errorId = hyperlink.substring(3); // e.g. _E_4e5b
    }
  }
  return errorId;
};

const emptyOrWhiteSpace = (str: string): boolean => {
  let res: boolean = true;
  if (!str) return res;
  res = /^\s+$/.test(str);
  return res;
};

const getBlockBookmarks = async (blockName: string) => {
  const paragraphs = await getMultiBlocksParagraphs([blockName]);
  const parIds: string[] = [];
  const bookmarks: string[] = [];

  await Word.run(async (context) => {
    const documentRange = context.document.body.getRange();

    // Get all the ranges that only consist of hyperlinks.
    const hyperLinks = documentRange.getHyperlinkRanges();
    hyperLinks.load('hyperlink,text');
    await context.sync();

    // Log each hyperlink.
    hyperLinks.items.forEach((linkRange) => {
      console.log(
        'body hyperlink: ' + linkRange.hyperlink + ' , text:' + linkRange.text
      );
    });

    const allbookmarks = documentRange.getBookmarks(true);
    await context.sync();

    console.log(allbookmarks.value);

    // Iterate through each paragraph
    for (let i = 0; i < paragraphs.length; i++) {
      parIds.push(paragraphs[i].uniqueLocalId);
    }
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });

  for (let i = 0; i < parIds.length; i++) {
    const bks = await getParagraphBookmarks(parIds[i]);
    bookmarks.push(...bks);
  }

  return bookmarks;
};

const getParagraphBookmarks = async (parId: string) => {
  const bookmarks: string[] = [];
  await Word.run(async (context) => {
    let paragraph = context.document.getParagraphByUniqueLocalId(parId);
    const range = paragraph.getRange(Word.RangeLocation.content);
    const bkmrk = range.getBookmarks(true);
    await context.sync();
    if (bkmrk.value) {
      console.log(bkmrk.value);
      const bk = bkmrk.value;
      for (let b = 0; b < bk.length; b++) {
        const bkRange = context.document.getBookmarkRangeOrNullObject(bk[b]);
        bkRange.load('text');
        await context.sync();
        console.log('Bookmark:' + bk[b] + ' - text:' + bkRange.text);
      }
      bookmarks.push(...bk);
    }
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });
  return bookmarks;
};

const getParagraphTextWithoutReferences = async (parId: string) => {
  const bookTexts: string[] = [];
  let parText = '';
  await Word.run(async (context) => {
    let paragraph = context.document.getParagraphByUniqueLocalId(parId);
    paragraph.load('text');
    const range = paragraph.getRange(Word.RangeLocation.content);
    const bkmrk = range.getBookmarks(true);
    await context.sync();
    parText = paragraph.text;
    if (bkmrk.value) {
      console.log(bkmrk.value);
      const bk = bkmrk.value;
      for (let b = 0; b < bk.length; b++) {
        if (bk[b].includes('_R')) {
          const bkRange = context.document.getBookmarkRangeOrNullObject(bk[b]);
          bkRange.load('text');
          await context.sync();
          console.log('Bookmark:' + bk[b] + ' - text:' + bkRange.text);
          bookTexts.push(bkRange.text);
        }
      }
    }
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });
  for (let i = 0; i < bookTexts.length; i++) {
    parText = parText.replace(' ' + bookTexts[i], '');
  }

  return parText;
};

const addKeywordBookmarks = async (blockName: string, keyword: string) => {
  const result: Word.Paragraph[] = [];

  await Word.run(async (context) => {
    // Create a proxy object for the document body.
    const body = context.document.body;

    // Load the paragraphs from the document
    body.load('paragraphs');
    await context.sync();
    let blockContent = false;

    // Iterate through each paragraph
    for (let i = 0; i < body.paragraphs.items.length; i++) {
      const paragraph = body.paragraphs.items[i];

      // Get the text of the current paragraph
      paragraph.load('text');
      paragraph.load('uniqueLocalId');
      //console.log('text:' + paragraph.text);

      const rangeCol = paragraph.search(keyword, { matchCase: false });
      rangeCol.load('items');
      await context.sync();
      for (let b = 0; b < rangeCol.items.length; b++) {
        const hexString = getRandomHexId();
        const range = rangeCol.items[b].getRange();
        range.load('text');
        range.load('hyperlink');
        range.load('bookmarks');
        await context.sync();
        const bookmarks = range.getBookmarks(true, false);
        console.log('initial hyperlink:' + range.hyperlink);
        await context.sync();
        if (bookmarks.value.length == 0) {
          range.insertBookmark('_E' + hexString);
          if (!range.hyperlink) {
            range.hyperlink = '#_E' + hexString;
          }
        } else {
          console.log('current range text:' + range.text);
          console.log('current bookmarks:');
          console.log(bookmarks.value);

          if (!range.hyperlink) {
            range.hyperlink = '#_R' + hexString;
            range.insertBookmark('_R' + hexString);
          } else {
            //range.hyperlink = '#_ER' + hexString;
            range.hyperlink = '';
            context.document.deleteBookmark(bookmarks.value[0]);
          }
        }

        range.load('font');
        range.font.color = 'green';
        await context.sync();
        console.log('final hyperlink:' + range.hyperlink);
      }

      await context.sync();

      if (
        paragraph.text &&
        paragraph.text.includes('---') &&
        paragraph.text.includes(blockName)
      ) {
        blockContent = !paragraph.text.includes('/' + blockName);
      } else if (blockContent) {
        result.push(paragraph);
      }
    }
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });

  return result;
};

const insertBookmarkReference = async (
  parId: string,
  start: number,
  end: number,
  refId: string,
  brackets: boolean
) => {
  let charsCnt = 0;
  let textToInsert = '';
  if (parId) {
    await Word.run(async (context) => {
      await context.sync();
      let paragraph = context.document.getParagraphByUniqueLocalId(parId);
      if (paragraph) {
        const charRanges = paragraph.search('?', { matchWildcards: true });
        charRanges.load();
        await context.sync();
        console.log('charRanges.items.length=' + charRanges.items.length);
        console.log('refId=' + refId);
        console.log('start=' + start);
        console.log('end=' + end);

        // Check for double space.
        let doubleSpace = false;
        if (charRanges.items.length > end + 1) {
          charRanges.items[end + 1].load('text');
          charRanges.items[end + 2].load('text');
          await context.sync();
          if (
            charRanges.items[end + 1].text === ' ' &&
            /[\s.,;:!?'")}\]>\/\\]/.test(charRanges.items[end + 2].text)
          ) {
            doubleSpace = true;
            charRanges.items[end + 1].delete();
            await context.sync();
          }
        }

        const targetRange = charRanges.items[start].expandTo(
          charRanges.items[end]
        );
        targetRange.load();
        await context.sync();

        const spaceRange = targetRange.insertText(
          ' ',
          Word.InsertLocation.after
        );
        spaceRange.load();
        await context.sync();

        if (brackets) {
          textToInsert = '(' + refId + ')';
        } else {
          textToInsert = refId.toString();
        }

        const refIdRange = spaceRange.insertText(
          textToInsert,
          Word.InsertLocation.after
        );
        refIdRange.load();
        await context.sync();

        charsCnt = textToInsert.length + 1;
        if (doubleSpace) charsCnt--;

        const hexString = getRandomHexId();
        let bookmarkName = '_R_' + refId + '_' + hexString;
        bookmarkName = bookmarkName.replaceAll(',', 'C');
        bookmarkName = bookmarkName.replaceAll(' ', '');

        refIdRange.insertBookmark(bookmarkName);
        refIdRange.hyperlink = '#' + bookmarkName;
        refIdRange.font.color = REFERENCE_FONT_COLOR;
        refIdRange.font.underline = Word.UnderlineType.none;

        await context.sync();
      }
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  }

  return charsCnt;
};

const deleteReferencesWithBookmarks = async () => {
  return await deleteBookmarksWildcard('_R', true);
};

const deleteReferenceMarkers = async () => {
  return await deleteBookmarksWildcard('_R', false);
};

const deleteErrorMarkers = async () => {
  return await deleteBookmarksWildcard('_E', false);
};

const deleteBookmarksWildcard = async (
  wildcard: string,
  deleteWithText: boolean
) => {
  await Word.run(async (context) => {
    await context.sync();

    const documentRange = context.document.body.getRange();
    // Get all the ranges that only consist of hyperlinks.
    const hyperLinksRanges = documentRange.getHyperlinkRanges();
    hyperLinksRanges.load('hyperlink,text');
    await context.sync();

    // Log each hyperlink.
    for (let i = 0; i < hyperLinksRanges.items.length; i++) {
      console.log(
        'body deleting hyperlink: ' +
          hyperLinksRanges.items[i].hyperlink +
          ' , text:' +
          hyperLinksRanges.items[i].text
      );
      if (
        hyperLinksRanges.items[i].hyperlink &&
        hyperLinksRanges.items[i].hyperlink.includes(wildcard)
      ) {
        if (deleteWithText) {
          hyperLinksRanges.items[i].delete();
        } else {
          hyperLinksRanges.items[i].hyperlink = '';
          const bookmarks = hyperLinksRanges.items[i].getBookmarks(true);
          hyperLinksRanges.items[i].font.color = REGULAR_FONT_COLOR;
          await context.sync();
          for (let b = 0; b < bookmarks.value.length; b++) {
            context.document.deleteBookmark(bookmarks.value[b]);
          }
        }
      }
    }
    await context.sync();
    const allbookmarks = documentRange.getBookmarks(true);
    await context.sync();

    console.log(allbookmarks.value);
  }).catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });
};

const findBookmarkRange = async (
  context: Word.RequestContext,
  bookmarkName: string
): Promise<Word.Range | null> => {
  const body = context.document.body.getRange();
  const bookmarks = body.getBookmarks(true, true);

  await context.sync();

  for (const bookmark of bookmarks.value) {
    console.log('Bookmark Name:', bookmark);
    if (bookmark.includes(bookmarkName)) {
      const bookmarkRange = context.document.getBookmarkRangeOrNullObject(bookmark);
      bookmarkRange.load();
      await context.sync();
      if (!bookmarkRange.isNullObject) {
        return bookmarkRange;
      }
    }
  }
  return null;
};

const updateErrorWithText = async (error: testErrorType) => {
  const bookmarkName = '_E_' + error.id;

  const newText = error.context.substring(error.corrStart, error.corrEnd + 1);
  let result = false;

  await Word.run(async (context) => {
    const bookmarkRange = await findBookmarkRange(context, bookmarkName);

    if (bookmarkRange) {
      const par = bookmarkRange.paragraphs.getFirstOrNullObject();
      if (par) {
        par.load(['text', 'uniqueLocalId']);
        await context.sync();

        const contextIdx = error.contextIdx;
        const charRanges = par.search('?', { matchWildcards: true });

        charRanges.load();
        await context.sync();

        const targetRange = charRanges.items[
          error.errStart + contextIdx
        ].expandTo(charRanges.items[error.errEnd + contextIdx]);

        targetRange.load();
        await context.sync();

        targetRange.insertText(newText, Word.InsertLocation.replace);

        bookmarkRange.font.color = REGULAR_FONT_COLOR;
        context.document.deleteBookmark('_E_' + error.id);

        bookmarkRange.select();

        await context.sync();
        result = true;
      }
    }
  }).catch((error) => {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });

  return result;
};

const scrollErrorIntoView = async (error: testErrorType) => {
  const bookmarkName = '_E_' + error.id;
  let result = false;

  await Word.run(async (context) => {
    const bookmarkRange = await findBookmarkRange(context, bookmarkName);

    if (bookmarkRange) {
      // Scroll the bookmark range into view
      bookmarkRange.select();
      await context.sync();
      result = true;
    }
  }).catch((error) => {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });

  return result;
};

export {
  updateDocumentWithContent,
  getMultiBlocksParagraphs,
  getMultiBlocksParagraphsWithProgress,
  insertBlockParagraphs,
  deleteBlockParagraphs,
  deleteBlocksMarkupParagraphs,
  findParagraphByText,
  removeParagraphById,
  removeParagraphByText,
  insertTermDescriptionAfterText,
  insertParagraphAfterParId,
  updateParagraphTextByParId,
  insertParagraphAfterParagraph,
  deleteInlineReferencesMultipleBlocks,
  insertErrorMarkerIntoParId,
  getErrorIdFromParId,
  getErrorIdFromText,
  getGptErrorFromText,
  getBookmarkErrorIdFromParId,
  insertBookmarkErrorIntoParId,
  getParagraphBookmarks,
  emptyOrWhiteSpace,
  getBlockBookmarks,
  addKeywordBookmarks,
  insertBookmarkReference,
  deleteReferencesWithBookmarks,
  deleteReferenceMarkers,
  deleteErrorMarkers,
  getParagraphTextWithoutReferences,
  updateErrorWithText,
  scrollErrorIntoView,
  getDocumentId,
  getDocumentLanguage,
  setDocumentLanguage,
  getRandomHexId,
};
