import { useEffect, useState } from 'react';

import { testErrorType } from 'engine/localStorageHelper';
import * as classes from 'components/ConsistencyItem/ConsistencyItem.scss';
import Button from 'components/Button/Button';
import { UIStrings } from 'app/UIStrings';
import InputError from '../InputError/InputError';

type ErrorItemProps = {
  testName: string;
  testTitle: string;
  testErrors: testErrorType[];
  rescanTestErrors: (testName: string) => void;
  acceptTestError?: (testName: string, testError: testErrorType) => void;
  jumpToError?: (testError: testErrorType) => void;
  skipTestError?: (
    testName: string,
    testError: testErrorType,
    skipReason: string
  ) => void;
};

const ErrorItem = ({
  testName,
  testTitle,
  testErrors,
  rescanTestErrors,
  acceptTestError,
  jumpToError,
  skipTestError,
}: ErrorItemProps) => {
  const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(false);

  const [isSkipReasonOpen, setIsSkipReasonOpen] = useState<boolean[]>(
    Array(testErrors.length).fill(false)
  );
  const [skipReason, setSkipReason] = useState<string[]>(
    Array(testErrors.length).fill(undefined)
  );

  const [errors, setErrors] = useState<testErrorType[]>(testErrors);

  useEffect(() => {
    setErrors(testErrors);
  }, [testErrors]);

  const getHighlightedText = (testError: testErrorType) => {
    const context = testError.context;
    const errStart = testError.errStart;
    const errEnd = testError.errEnd;
    const corrStart = testError.corrStart;
    const corrEnd = testError.corrEnd;

    const contextArr = context.split('');
    let highlightedError = '';
    let highlightedCorrection = '';

    const errorSubstring = contextArr.slice(errStart, errEnd + 1).join('');

    if (corrStart >= 0 && corrEnd >= 0) {
      const correctionSubstring = contextArr
        .slice(corrStart, corrEnd + 1)
        .join('');

      highlightedError = `<del style="color: #D02B49">${errorSubstring}</del>`;
      highlightedCorrection = `<span style="color: #1267B4">${correctionSubstring} </span>`;

      contextArr.splice(errStart, errEnd - errStart + 1, highlightedError);
      contextArr.splice(
        corrStart - (errEnd - errStart),
        corrEnd - corrStart + 1,
        highlightedCorrection
      );
    } else {
      highlightedError = `<span style="color: #D02B49">${errorSubstring}</span>`;

      contextArr.splice(errStart, errEnd - errStart + 1, highlightedError);
    }

    const result = contextArr.join('');

    return (
      <div
        className={classes.testErrorContext}
        dangerouslySetInnerHTML={{
          __html: result,
        }}
      />
    );
  };

  return (
    <div
      className={classes.container}
      style={{
        background:
          errors.filter((error) => !error.skip).length > 0
            ? '#F5F5F5'
            : '#EDF2FB',
      }}
    >
      <div className={classes.testTitleWrapper}>
        {errors.filter((error) => !error.skip).length > 0 ? (
          <div className={`${classes.icon} ${classes.errorCircleIcon}`}></div>
        ) : (
          <div className={`${classes.icon} ${classes.applyIcon}`}></div>
        )}
        <div className={classes.testTitle}>
          {testTitle}
          <span className={classes.testErrorRate}>
            ({errors.filter((error) => !error.skip).length})
          </span>
        </div>
        <div className={classes.testOptions}>
          <div
            className={`${classes.icon} ${classes.refreshIcon20}`}
            onClick={() => rescanTestErrors(testName)}
          ></div>

          {errors.filter((error) => !error.skip).length > 0 &&
            isDescriptionOpen ? (
            <div
              className={`${classes.icon} ${classes.arrowDownErrorsIcon}`}
              onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
            ></div>
          ) : (
            <div
              className={`${classes.icon} ${classes.arrowUpErrorsIcon}`}
              onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
            ></div>
          )}
        </div>
      </div>

      {errors.length > 0 &&
        isDescriptionOpen &&
        errors.map(
          (testError: testErrorType, index: number) =>
            !testError.skip && (
              <div className={classes.testErrorWrapper} key={testError.id}>
                {getHighlightedText(testError)}
                <div className={classes.testErrorOptions}>
                  {testError.corrStart >= 0 && testError.corrEnd >= 0 ? (
                    <Button
                      title={UIStrings.consistency_item.accept}
                      color="blue"
                      size="small"
                      onClick={() => acceptTestError(testName, testError)}
                    />
                  ) : (
                    <div
                      className={`${classes.icon} ${classes.refreshIcon16}`}
                      style={{
                        background: '#1267B4',
                      }}
                      onClick={() => rescanTestErrors(testName)}
                    ></div>
                  )}

                  <Button
                    title={UIStrings.consistency_item.ignore}
                    color="transparent"
                    size="small"
                    onClick={() =>
                      setIsSkipReasonOpen((prev) => {
                        const newIsSkipReasonOpen = prev;
                        newIsSkipReasonOpen[index] = true;

                        return [...newIsSkipReasonOpen];
                      })
                    }
                  />

                {/* Use contextIdx as a way to differentiate between errors with bookmarks (to which one can scroll)
                    and errors which do not have a clear localisation.*/}
                {testError.contextIdx != 0 && (
                  <div
                    title={UIStrings.consistency_item.view}
                    className={`${classes.icon} ${classes.eyeSolidIcon}`}
                    style={{
                      background: '#1267B4',
                    }}
                    onClick={() => jumpToError(testError)}
                  ></div>
                )}
                </div>

                {isSkipReasonOpen[index] && (
                  <div className={classes.skipReasonForm}>
                    <textarea
                      placeholder={
                        UIStrings.consistency_item.skip_reason_placeholder
                      }
                      value={skipReason[index]}
                      onChange={(e) =>
                        setSkipReason((prev) => {
                          const newSkipReason = prev;
                          newSkipReason[index] = e.target.value;

                          return [...newSkipReason];
                        })
                      }
                    ></textarea>

                    <Button
                      title={UIStrings.consistency_item.ignore}
                      color="blue"
                      size="small"
                      disabled={
                        skipReason[index] ? !skipReason[index].trim() : true
                      }
                      onClick={() =>
                        skipTestError(testName, testError, skipReason[index])
                      }
                    />
                  </div>
                )}
              </div>
            )
        )}
    </div>
  );
};

export default ErrorItem;
