import { TempStorage, tempStorage } from 'app/index';
import {
  DISTANCES_FOUND_PERCENT_THRESHOLD,
  fetchDistances,
  fetchGetConsistencyList,
  fetchLogin,
} from './apiHelpers';
import {
  checkAlgoAllSubjectivesConsistency,
  checkAlgoClaimsAmountConsistency,
  checkAlgoClaimsDescriptionsConsistency,
  checkAlgoClaimsNumberingConsistency,
  checkGptClaimsConsistencyParallel,
} from './checkClaims';
import { checkReferencesConsistency } from './checkReferences';
import { updateErrorWithText, scrollErrorIntoView } from './documentProxy';
import { getTestErrorById, testErrorType } from './localStorageHelper';
import {
  ConsistencyInstructionItem,
  PromiseStatus,
  StatusCodes,
  StatusManager,
} from './models';
import { UIStrings } from 'app/UIStrings';

const CONTEXT_SPREAD: number = 25;

const checkAnyConsistency = async (
  statusManager: StatusManager,
  testKey: string,
  promiseStatus: PromiseStatus,
  resolve
) => {
  if (testKey.startsWith('references')) {
    await checkReferencesConsistency(statusManager, promiseStatus, resolve);
  } else {
    const consItem = getConsistencyByTestKey(testKey);
    if (consItem.modelType === 'algo') {
      // Algo types.
      await checkAlgoConsistency(
        statusManager,
        consItem,
        promiseStatus,
        resolve
      );
    } else {
      //await checkGptClaimsConsistency(
      await checkGptClaimsConsistencyParallel(
        statusManager,
        consItem,
        promiseStatus,
        resolve
      );
    }
  }
};

const checkAlgoConsistency = async (
  statusManager: StatusManager,
  consItem: ConsistencyInstructionItem,
  promiseStatus: PromiseStatus,
  resolve
) => {
  switch (consItem.testKey) {
    case 'claims-amount': {
      await checkAlgoClaimsAmountConsistency(
        statusManager,
        consItem,
        promiseStatus,
        resolve
      );
      break;
    }
    case 'claims-numbering': {
      await checkAlgoClaimsNumberingConsistency(
        statusManager,
        consItem,
        promiseStatus,
        resolve
      );
      break;
    }
    case 'claims-descriptions': {
      await checkAlgoClaimsDescriptionsConsistency(
        statusManager,
        consItem,
        promiseStatus,
        resolve
      );
      break;
    }
    case 'common-subjectives':
    case 'common-ambiguous': {
      await checkAlgoAllSubjectivesConsistency(
        statusManager,
        consItem,
        promiseStatus,
        resolve
      );
      break;
    }
  }
};

const getFirstWord = (
  text: string,
  startIndex: number,
  allowComma: boolean
) => {
  let word = '';
  let stopChars: string[] = ['.', ',', ';', ' ', '\r', '\t', '\n'];
  if (allowComma) {
    stopChars = ['.', ';', ' ', '\r', '\t', '\n'];
  }
  let opened = false;
  for (let i = startIndex; i < text.length; i++) {
    if (
      !stopChars.includes(text.charAt(i)) ||
      (word.length > 0 && word.endsWith(','))
    ) {
      if (text.charAt(i) === '<') {
        if (!opened) {
          opened = true;
          word += text.charAt(i);
        } else {
          break;
        }
      } else {
        word += text.charAt(i);
      }
    } else {
      if (word.length > 0) {
        break;
      }
    }
  }
  return word;
};

const getContext = (text: string, startIndex: number, endIndex: number) => {
  const startContextIndex = Math.max(0, startIndex - CONTEXT_SPREAD);
  const endContextIndex = Math.min(endIndex + CONTEXT_SPREAD, text.length - 1);
  let stopChars: string[] = ['.', ',', ';', ' ', '\r', '\t', '\n'];

  let context = text.substring(startContextIndex, endContextIndex + 1);

  let trimmedLeft = '';
  let result = '';

  if (startContextIndex > 0) {
    // If context is not started from the beginning of paragraph.
    let active = false;
    for (let i = 0; i < context.length; i++) {
      if (active) {
        trimmedLeft += context[i];
      }
      if (!active && stopChars.includes(context[i])) {
        if (i + 1 < context.length && !stopChars.includes(context[i + 1])) {
          active = true;
        }
      }
    }
  } else {
    trimmedLeft = context;
  }

  if (endContextIndex < text.length - 1) {
    // context finished earlier than paragraph
    let active = false;
    for (let i = trimmedLeft.length - 1; i >= 0; i--) {
      if (active) {
        result = trimmedLeft[i] + result;
      }
      if (!active && stopChars.includes(trimmedLeft[i])) {
        if (i - 1 >= 0 && !stopChars.includes(trimmedLeft[i - 1])) {
          active = true;
        }
      }
    }
  } else {
    result = trimmedLeft;
  }

  return result;
};

const correctSingleError = async (
  statusManager: StatusManager,
  error: testErrorType,
  promiseStatus: PromiseStatus,
  resolve
) => {
  promiseStatus.progress = 0;
  statusManager.setProgress(promiseStatus.progress);
  const res = await updateErrorWithText(error);

  promiseStatus.progress = 100;
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.Success);
  statusManager.setStatusMessage(UIStrings.statusbar.error_corrected);

  resolve({ completed: true });
};

const viewError = async (
  error: testErrorType,
  resolve
) => {
  const res = await scrollErrorIntoView(error);
  resolve({ completed: true });
};

const refreshConsistencyList = async () => {
  const resp = await fetchGetConsistencyList();
  console.log(resp);
  if (resp && !resp.error) {
    tempStorage.consistencyList = resp.items;
  }
};

const getConsistencyByTestKey = (testKey: string) => {
  const consItem: ConsistencyInstructionItem = tempStorage.consistencyList.find(
    (test) => test.testKey === testKey
  );
  return consItem;
};

export {
  checkReferencesConsistency,
  checkAnyConsistency,
  correctSingleError,
  viewError,
  refreshConsistencyList,
  getConsistencyByTestKey,
  getFirstWord,
  getContext,
};
