/* global Word */

import { tempStorage } from 'app/index';
import {
  DISTANCES_FOUND_PERCENT_THRESHOLD,
  fetchAllDistances,
  fetchCommon,
  fetchDetailedClaim,
  fetchDistances,
  fetchFigure,
  fetchGenerateReport,
  fetchReferences,
  fetchRoots,
  fetchSearchStemmed,
  fetchTermLookup,
  fetchTermsDescriptions,
  fetchUpdateDescription,
} from './apiHelpers';
import {
  deleteBlockParagraphs,
  deleteBlocksMarkupParagraphs,
  deleteErrorMarkers,
  deleteReferenceMarkers,
  deleteReferencesWithBookmarks,
  getMultiBlocksParagraphs,
  getMultiBlocksParagraphsWithProgress,
  getParagraphBookmarks,
  getParagraphTextWithoutReferences,
  insertBlockParagraphs,
  insertBookmarkReference,
  insertParagraphAfterParId,
  removeParagraphById,
} from './documentProxy';
import {
  checkDefinitionLocalStorage,
  consistencyType,
  definitionType,
  deleteDynamicDefinitions,
  getLocalStorage,
  getTestErrors,
  localStorageType,
  referenceType,
  suggestionType,
  updateClaimDescriptions,
  updateClaims,
  updateLocalStorage,
} from './localStorageHelper';
import {
  FillZoneType,
  PromiseStatus,
  StatusCodes,
  StatusManager,
  defaultPromiseStatus,
} from './models';
import { UIStrings } from 'app/UIStrings';
import { RouterContextType } from 'app/views/App/App';
import { useOutletContext } from 'react-router-dom';

const getDefaultPromiseStatus = () => {
  const promiseStatus: PromiseStatus = {
    progress: defaultPromiseStatus.progress,
    aborted: defaultPromiseStatus.aborted,
    bulkTaskCount: defaultPromiseStatus.bulkTaskCount,
    currentTaskId: defaultPromiseStatus.currentTaskId,
  };
  return promiseStatus;
};

const setTotalProgressInBulk = (
  promiseStatus: PromiseStatus,
  localProgress: number
) => {
  let progress = 0;
  if (promiseStatus.bulkTaskCount <= 1) {
    progress = localProgress;
  } else {
    progress =
      localProgress / promiseStatus.bulkTaskCount +
      (promiseStatus.currentTaskId / promiseStatus.bulkTaskCount) * 100;
  }

  promiseStatus.progress = progress;
};

const getStatusMessageInBulk = (
  promiseStatus: PromiseStatus,
  statusMessage: string
) => {
  let bulkTaskStr = '';
  if (promiseStatus.bulkTaskCount > 1) {
    bulkTaskStr =
      ' (' +
      (promiseStatus.currentTaskId + 1).toString() +
      '/' +
      promiseStatus.bulkTaskCount +
      ')';
  }
  return statusMessage + bulkTaskStr;
};

const fillAllClaimsDescriptions = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  if (promiseStatus.aborted) {
    resolve({ aborted: true });
    return;
  }

  let error = '';
  let httpCode = 0;

  setTotalProgressInBulk(promiseStatus, 0);
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(
    getStatusMessageInBulk(
      promiseStatus,
      UIStrings.statusbar.generating_descriptions
    )
  );

  const claimsMap = await reloadClaimsToLocalStorage();

  const len = claimsMap.claims.length;
  const claimDescriptions = [];

  for (let i = 0; i < claimsMap.claims.length; i++) {
    if (promiseStatus.aborted) {
      break;
    }
    const intKey = claimsMap.idx[i];

    setTotalProgressInBulk(promiseStatus, Math.round((i / len) * 100));
    statusManager.setProgress(promiseStatus.progress);

    const resp = await fetchDetailedClaim(
      claimsMap.claims[i],
      intKey,
      true,
      false
    );
    httpCode = resp.httpCode;
    if (!resp.error) {
      console.log('detailed claim:' + resp.data);
      claimDescriptions.push(resp.data);
    } else {
      error = 'detailed claim fetch error:' + resp.error;
      console.log(error);
    }
  }

  if (!promiseStatus.aborted) {
    if (!error) {
      await insertBlockParagraphs(
        'common',
        claimDescriptions,
        true,
        true,
        true,
        claimsMap.claims
      );

      updateClaimDescriptions(claimDescriptions);

      console.log('loop finished');
      setTotalProgressInBulk(promiseStatus, 100);
      statusManager.setProgress(promiseStatus.progress);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.description_created);
    } else {
      if (httpCode === 401) {
        statusManager.setStatusCode(StatusCodes.Unauthorized);
        statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.description_failed);
      }
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

const fillZone = async (
  statusManager: StatusManager,
  fillZoneType: FillZoneType,
  promiseStatus: PromiseStatus,
  resolve
) => {
  if (promiseStatus.aborted) {
    resolve({ aborted: true });
    return;
  }

  let taskTitle = '';
  let progressTaskTitle = '';
  let completedTaskTitle = '';
  let failedTaskTitle = '';
  let instructionType = '';
  let blockName = '';
  let beginning = true;
  let cleanup = true;

  // Prepare Title
  switch (fillZoneType) {
    case FillZoneType.SummaryTop:
      instructionType = 'summary-top';
      blockName = 'summaryTop';
      cleanup = true;
      beginning = true;
      taskTitle = UIStrings.tasks.intro_title;
      progressTaskTitle = UIStrings.tasks.intro_progress;
      completedTaskTitle = UIStrings.tasks.intro_completed;
      failedTaskTitle = UIStrings.tasks.intro_failed;
      statusManager.currentTaskId = 1;
      break;
    case FillZoneType.SummaryBottom:
      instructionType = 'summary-bottom';
      blockName = 'summaryBottom';
      cleanup = true;
      beginning = true;
      taskTitle = UIStrings.tasks.summary_title;
      progressTaskTitle = UIStrings.tasks.summary_progress;
      completedTaskTitle = UIStrings.tasks.summary_completed;
      failedTaskTitle = UIStrings.tasks.summary_failed;
      statusManager.currentTaskId = 3;
      break;
    case FillZoneType.Title:
      instructionType = 'title';
      blockName = 'title';
      cleanup = true;
      beginning = true;
      taskTitle = UIStrings.tasks.title_title;
      progressTaskTitle = UIStrings.tasks.title_progress;
      completedTaskTitle = UIStrings.tasks.title_completed;
      failedTaskTitle = UIStrings.tasks.title_failed;
      statusManager.currentTaskId = 0;
      break;
    default:
      instructionType = 'title';
      blockName = 'title';
      break;
  }

  console.log(taskTitle);

  setTotalProgressInBulk(promiseStatus, 0);
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(
    getStatusMessageInBulk(promiseStatus, progressTaskTitle)
  );

  const claimsMap = await reloadClaimsToLocalStorage();

  setTotalProgressInBulk(promiseStatus, 50);
  statusManager.setProgress(promiseStatus.progress);

  let claimsText = '';

  for (const [, value] of Object.entries(claimsMap)) {
    if (promiseStatus.aborted) {
      break;
    }
    claimsText += value + '\n';
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  } else {
    const commonResp = await fetchCommon(
      claimsText,
      instructionType,
      'gpt',
      false
    );
    if (!promiseStatus.aborted) {
      if (!commonResp.error) {
        if (fillZoneType === FillZoneType.Title) {
          tempStorage.patentTitle = commonResp.data;
        }

        await insertBlockParagraphs(
          blockName,
          [commonResp.data],
          true,
          beginning,
          cleanup
        );
        setTotalProgressInBulk(promiseStatus, 100);
        statusManager.setProgress(promiseStatus.progress);
        statusManager.setStatusCode(StatusCodes.Success);
        if (
          promiseStatus.currentTaskId == promiseStatus.bulkTaskCount - 1 &&
          promiseStatus.bulkTaskCount > 1
        ) {
          statusManager.setStatusMessage(
            UIStrings.statusbar.all_operations_completed
          );
        } else {
          statusManager.setStatusMessage(completedTaskTitle);
        }

        resolve({ completed: true });
      } else {
        if (commonResp.httpCode === 401) {
          statusManager.setStatusCode(StatusCodes.Unauthorized);
          statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
        } else {
          statusManager.setStatusCode(StatusCodes.Fail);
          statusManager.setStatusMessage(failedTaskTitle);
        }

        resolve({ completed: true });
      }
    }
  }
};

const saveDefinitionInDb = async (
  statusManager: StatusManager,
  definition: definitionType,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.inserting_definitions);

  const resp = await fetchUpdateDescription(
    definition.title,
    definition.description
  );

  if (!resp.error) {
    statusManager.setStatusCode(StatusCodes.Success);
    statusManager.setStatusMessage(UIStrings.statusbar.definition_saved);
  } else {
    if (resp.httpCode === 401) {
      statusManager.setStatusCode(StatusCodes.Unauthorized);
      statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
    } else {
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(
        UIStrings.statusbar.definition_save_failed
      );
    }
  }
  resolve({ completed: true });
};

const refreshDefinitions = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  deleteDynamicDefinitions();
  tempStorage.origTerms = [];
  tempStorage.totalTerms = [];
  tempStorage.definitionsFirstInsert = true;
  const localStorage = getLocalStorage();
  let definitions: definitionType[] = localStorage.definitions;
  const searchDefinitions: definitionType[] = [];
  if (!definitions) {
    definitions = [];
  }

  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.updating_definition);

  let allParagraphs = await getMultiBlocksParagraphsWithProgress(
    [
      'intro',
      'common',
      'definitions',
      'figures',
      'references',
      'claims',
      'summary',
    ],
    statusManager,
    promiseStatus,
    0,
    70
  );

  const paragraphTexts: string[] = [];
  const descriptionTexts: string[] = [];
  let error = '';
  let httpCode = 0;

  if (!promiseStatus.aborted) {
    // Iterate through each paragraph
    for (let i = 0; i < allParagraphs.length; i++) {
      const paragraph = allParagraphs[i];

      if (paragraph.text) {
        // Generate the related description
        const defs = await fetchTermsDescriptions(paragraph.text);

        if (!defs.error) {
          // Store original text as parentText
          let parentText = paragraph.text;
          paragraphTexts.push(parentText);

          // Store the related description for later insertion
          for (let j = 0; j < defs.paragraphs.length; j++) {
            if (j > 0) {
              parentText = defs.paragraphs[j - 1];
            }

            searchDefinitions.push({
              title: defs.origTerms[j],
              parentText: parentText,
              description: defs.paragraphs[j],
              checked: false,
              changed: false,
              custom: false,
            });

            console.log('par: ', parentText);
          }
        } else {
          error = defs.error;
          httpCode = defs.httpCode;
          console.error('fetchTermsDescriptions error. ' + defs.error);
          break;
        }
      }
    }

    if (!error) {
      localStorage.definitions = [...searchDefinitions, ...definitions];
      for (let i = 0; i < localStorage.definitions.length; i++) {
        descriptionTexts.push(localStorage.definitions[i].description);
      }

      // Test each definition for checked/unchecked state.
      const distancesResult = await fetchAllDistances(
        paragraphTexts,
        descriptionTexts
      );
      if (!distancesResult.error) {
        for (let i = 0; i < distancesResult.percentage.length; i++) {
          if (
            distancesResult.percentage[i] >= DISTANCES_FOUND_PERCENT_THRESHOLD
          ) {
            localStorage.definitions[distancesResult.indices[i]].checked = true;
          }
        }
        updateLocalStorage(localStorage);
      } else {
        error = distancesResult.error;
        httpCode = distancesResult.httpCode;
      }
    }

    if (error) {
      if (httpCode === 401) {
        statusManager.setStatusCode(StatusCodes.Unauthorized);
        statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.refresh_failed);
      }
    } else {
      statusManager.setProgress(100);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.definitions_updated);
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

const checkDefinition = async (
  statusManager: StatusManager,
  definition: definitionType,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.inserting_definitions);

  let allParagraphs = await getMultiBlocksParagraphs([
    'intro',
    'common',
    'definitions',
    'figures',
    'references',
    'claims',
    'summary',
  ]);

  const ids: number[] = [];
  const texts: string[] = [];

  // Iterate through each paragraph
  for (let i = 0; i < allParagraphs.length; i++) {
    const paragraph = allParagraphs[i];

    if (paragraph.text) {
      texts.push(paragraph.text);
      ids.push(i);
    }
  }

  const resp = await fetchTermLookup(texts, definition.title);
  let parentParagraph: Word.Paragraph;

  if (!resp.error) {
    parentParagraph = allParagraphs[ids[resp.index]];
    definition.parentText = parentParagraph.text;
    if (promiseStatus.aborted) {
      resolve({ aborted: true });
    } else {
      let res = false;
      if (parentParagraph) {
        res = await insertParagraphAfterParId(
          parentParagraph.uniqueLocalId,
          definition.description
        );
      }
      statusManager.setProgress(100);
      if (res) {
        checkDefinitionLocalStorage(definition);
        statusManager.setStatusCode(StatusCodes.Success);
        statusManager.setStatusMessage(UIStrings.statusbar.definitions_added);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(
          UIStrings.statusbar.definitions_insert_failed
        );
      }
    }
  } else {
    if (resp.httpCode === 401) {
      statusManager.setStatusCode(StatusCodes.Unauthorized);
      statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
    } else {
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(UIStrings.statusbar.definition_not_found);
    }
  }
  resolve({ completed: true });
};

const uncheckDefinition = async (
  statusManager: StatusManager,
  definition: definitionType,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.removing_definition);

  let allParagraphs = await getMultiBlocksParagraphs([
    'intro',
    'common',
    'definitions',
    'figures',
    'references',
    'claims',
    'summary',
  ]);

  let texts: string[] = [];
  let ids: string[] = [];

  // Iterate through each paragraph
  for (let i = 0; i < allParagraphs.length; i++) {
    const paragraph = allParagraphs[i];

    if (paragraph.text) {
      texts.push(paragraph.text);
      ids.push(paragraph.uniqueLocalId);
    }
  }

  const distancesResponse = await fetchDistances(definition.description, texts);

  if (!distancesResponse.error) {
    const max = Math.max(...distancesResponse.percentage);
    let parId;

    if (max >= DISTANCES_FOUND_PERCENT_THRESHOLD) {
      const index = distancesResponse.percentage.indexOf(max);
      console.log(index);
      if (index >= 0) {
        parId = ids[index];
      }
    }

    if (promiseStatus.aborted) {
      resolve({ aborted: true });
    } else {
      let res = false;
      if (parId) {
        res = await removeParagraphById(parId);
      }

      statusManager.setProgress(100);
      if (res) {
        checkDefinitionLocalStorage(definition);
        statusManager.setStatusCode(StatusCodes.Success);
        statusManager.setStatusMessage(UIStrings.statusbar.definition_removed);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.remove_failed);
      }
      resolve({ completed: true });
    }
  } else {
    if (distancesResponse.httpCode === 401) {
      statusManager.setStatusCode(StatusCodes.Unauthorized);
      statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
    } else {
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(UIStrings.statusbar.remove_failed);
    }
    resolve({ completed: true });
  }
};

const checkMultipleDefinitions = async (
  statusManager: StatusManager,
  definitions: definitionType[],
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.inserting_definitions);

  let fail = true;
  let httpCode = 0;

  for (let d = 0; d < definitions.length; d++) {
    const definition = definitions[d];
    const definitionsLines: string[] = [];

    let allParagraphs = await getMultiBlocksParagraphs([
      'intro',
      'common',
      'definitions',
      'figures',
      'references',
      'claims',
      'summary',
    ]);

    const ids: number[] = [];
    const texts: string[] = [];

    // Iterate through each paragraph
    for (let i = 0; i < allParagraphs.length; i++) {
      const paragraph = allParagraphs[i];

      if (paragraph.text) {
        texts.push(paragraph.text);
        ids.push(i);
      }
    }

    const resp = await fetchTermLookup(texts, definition.title);
    let parentParagraph: Word.Paragraph;
    httpCode = resp.httpCode;
    if (!resp.error) {
      parentParagraph = allParagraphs[ids[resp.index]];
      if (parentParagraph) {
        definition.parentText = parentParagraph.text;
        if (promiseStatus.aborted) {
          resolve({ aborted: true });
        } else {
          let res = false;

          if (true) {
            res = true;
            definitionsLines.push(definition.description);
          }
          if (res) {
            checkDefinitionLocalStorage(definition);
            fail = false;
          }
        }
        await insertBlockParagraphs(
          'definitions',
          definitionsLines,
          false,
          false,
          tempStorage.definitionsFirstInsert
        );
        tempStorage.definitionsFirstInsert = false;
      }
    } else {
      // Insert definition even if it is not present in text.
      await insertBlockParagraphs(
        'definitions',
        [definition.description],
        false,
        false,
        tempStorage.definitionsFirstInsert
      );
      tempStorage.definitionsFirstInsert = false;
      checkDefinitionLocalStorage(definition);
      fail = false;
    }
    statusManager.setProgress(((d + 1) * 100) / definitions.length);
  }
  // If at least one was checked, then it is Success.
  if (fail) {
    if (httpCode === 401) {
      statusManager.setStatusCode(StatusCodes.Unauthorized);
      statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
    } else {
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(UIStrings.statusbar.definition_not_found);
    }
  } else {
    statusManager.setStatusCode(StatusCodes.Success);
    statusManager.setStatusMessage(UIStrings.statusbar.definitions_added);
  }
  resolve({ completed: true });
};

const uncheckMultipleDefinitions = async (
  statusManager: StatusManager,
  definitions: definitionType[],
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.removing_definition);

  let fail = false;
  let httpCode = 0;

  for (let d = 0; d < definitions.length; d++) {
    const definition = definitions[d];

    let allParagraphs = await getMultiBlocksParagraphs([
      'intro',
      'common',
      'definitions',
      'figures',
      'references',
      'claims',
      'summary',
    ]);

    let texts: string[] = [];
    let ids: string[] = [];

    // Iterate through each paragraph
    for (let i = 0; i < allParagraphs.length; i++) {
      const paragraph = allParagraphs[i];

      if (paragraph.text) {
        texts.push(paragraph.text);
        ids.push(paragraph.uniqueLocalId);
      }
    }

    const distancesResponse = await fetchDistances(
      definition.description,
      texts
    );
    if (!distancesResponse.error) {
      const max = Math.max(...distancesResponse.percentage);
      let parId;

      if (max >= DISTANCES_FOUND_PERCENT_THRESHOLD) {
        const index = distancesResponse.percentage.indexOf(max);
        console.log(index);
        if (index >= 0) {
          parId = ids[index];
        }
      }

      if (promiseStatus.aborted) {
        resolve({ aborted: true });
      } else {
        let res = false;
        if (parId) {
          res = await removeParagraphById(parId);
        }

        statusManager.setProgress(((d + 1) * 100) / definitions.length);
        if (res) {
          checkDefinitionLocalStorage(definition);
        } else {
          fail = false;
        }
      }
    } else {
      break;
    }
  }
  if (fail) {
    if (httpCode === 401) {
      statusManager.setStatusCode(StatusCodes.Unauthorized);
      statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
    } else {
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(UIStrings.statusbar.remove_failed);
    }
  } else {
    statusManager.setStatusCode(StatusCodes.Success);
    statusManager.setStatusMessage(UIStrings.statusbar.definitions_removed);
  }
  resolve({ completed: true });
};

const refreshReferences = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  const localStorage = getLocalStorage();
  const references: referenceType[] = localStorage.references
    ? localStorage.references
    : [];
  const suggestions: suggestionType[] = [];

  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.refresh_references);

  let allParagraphs = await getMultiBlocksParagraphsWithProgress(
    ['intro', 'common', 'figures', 'references', 'claims', 'summary'],
    statusManager,
    promiseStatus,
    0,
    70
  );

  const paragraphTexts: string[] = [];

  if (!promiseStatus.aborted) {
    // Iterate through each paragraph
    for (let i = 0; i < allParagraphs.length; i++) {
      const paragraph = allParagraphs[i];

      if (paragraph.text) {
        // Store original text as parentText
        let parentText = paragraph.text;
        paragraphTexts.push(parentText);
      }
    }
    const wholeText = paragraphTexts.join('\n');
    const aiRefs = await fetchReferences(wholeText);

    if (aiRefs && !aiRefs.error) {
      for (let i = 0; i < aiRefs.references.length; i++) {
        const newSuggestion: suggestionType = {
          title: aiRefs.references[i],
          enabled: true,
        };
        for (let j = 0; j < references.length; j++) {
          if (
            aiRefs.references[i].toLowerCase() ===
            references[j].title.toLowerCase()
          ) {
            newSuggestion.enabled = false;
            break;
          }
        }
        suggestions.push(newSuggestion);
      }

      localStorage.suggestions = suggestions;

      updateLocalStorage(localStorage);
      statusManager.setProgress(100);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.references_updated);
    } else {
      if (aiRefs.httpCode === 401) {
        statusManager.setStatusCode(StatusCodes.Unauthorized);
        statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.refresh_failed);
      }
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

const fillLeftPhrases = (refs: referenceType[], roots: string[]) => {
  const leftPhrases: string[] = [];
  for (let i = 0; i < refs.length; i++) {
    const rootStart = refs[i].title.indexOf(roots[i]);
    const lpLen = rootStart + roots[i].length;
    refs[i].leftPhrase = refs[i].title.substring(0, lpLen);
    leftPhrases.push(refs[i].leftPhrase);
  }
  return leftPhrases;
};

const updateRoots = async () => {
  const findDuplicates = (arr: string[]) =>
    arr.filter((item, index) => arr.indexOf(item) !== index);

  const localStorage = getLocalStorage();
  // Update root words.
  let refs = localStorage.references;
  const titles: string[] = [];
  refs.forEach((element) => {
    titles.push(element.title);
  });
  const res = await fetchRoots(titles);
  if (!res.error && res.roots.length === refs.length) {
    for (let i = 0; i < res.roots.length; i++) {
      if (!res.roots[i] || res.roots[i].length === 1) {
        res.roots[i] = refs[i].title;
      }
    }

    const leftPhrases = fillLeftPhrases(refs, res.roots);
    const duplicates = [...new Set(findDuplicates(leftPhrases))];
    for (let i = 0; i < res.roots.length; i++) {
      refs[i].rootWord = res.roots[i];
      // We add ref number after the main word only if roots are not duplicated and contains more than 1 word
      refs[i].searchWholePhrase = false;

      if (
        refs[i].rootWord === refs[i].title ||
        duplicates.includes(res.roots[i])
      ) {
        //const leftPhraseDuplicates =
        refs[i].searchWholePhrase = true;
      }
    }

    localStorage.references = refs;
    console.log('Refs with Updated roots:');
    console.log(refs);
    updateLocalStorage(localStorage);
  }
};

const analyzeReferences = () => {
  const analyzeReferencesResult = {
    keywords: [] as string[],
    roots: [] as string[],
    references: {} as { [id: string]: string },
    refSummary: [] as string[],
  };

  const localStorage = getLocalStorage();

  for (let i = 0; i < localStorage.references.length; i++) {
    let refKey = localStorage.references[i].title;
    if (
      !localStorage.references[i].searchWholePhrase &&
      localStorage.references[i].leftPhrase &&
      /\S/.test(localStorage.references[i].leftPhrase)
    ) {
      refKey = localStorage.references[i].leftPhrase;
    }
    analyzeReferencesResult.keywords.push(refKey);
    analyzeReferencesResult.roots.push(localStorage.references[i].rootWord);

    const idStr = localStorage.references[i].referenceNumber.toString();

    if (analyzeReferencesResult.references[refKey]) {
      analyzeReferencesResult.references[refKey] =
        analyzeReferencesResult.references[refKey] + ', ' + idStr;
    } else {
      analyzeReferencesResult.references[refKey] = idStr;
    }

    analyzeReferencesResult.refSummary.push(
      idStr + '\t' + localStorage.references[i].title
    );
  }

  return analyzeReferencesResult;
};

const applyReferences = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  setTotalProgressInBulk(promiseStatus, 0);
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.inserting_references);

  await updateRoots();

  await deleteBlockParagraphs('references');

  setTotalProgressInBulk(promiseStatus, 10);
  statusManager.setProgress(promiseStatus.progress);

  await deleteReferencesWithBookmarks();

  setTotalProgressInBulk(promiseStatus, 20);
  statusManager.setProgress(promiseStatus.progress);

  let allParagraphs = await getMultiBlocksParagraphs(['figures']);

  setTotalProgressInBulk(promiseStatus, 30);
  statusManager.setProgress(promiseStatus.progress);

  let claimsParagraphsCount = 0;

  let claimsParagraphs = await getMultiBlocksParagraphs(['claims']);
  claimsParagraphsCount = claimsParagraphs.length;
  allParagraphs.push(...claimsParagraphs);

  setTotalProgressInBulk(promiseStatus, 40);
  statusManager.setProgress(promiseStatus.progress);

  if (!promiseStatus.aborted) {
    const ares = analyzeReferences();

    const paragraphTexts: string[] = [];

    // Iterate through each paragraph
    for (let i = 0; i < allParagraphs.length; i++) {
      const paragraph = allParagraphs[i];

      // Store original text
      paragraphTexts.push(paragraph.text);
    }

    const resp = await fetchSearchStemmed(
      paragraphTexts,
      ares.keywords,
      ares.roots
    );

    if (resp.error) {
      if (resp.httpCode === 401) {
        statusManager.setStatusCode(StatusCodes.Unauthorized);
        statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.insert_failed);
      }
    } else {
      setTotalProgressInBulk(promiseStatus, 50);
      statusManager.setProgress(promiseStatus.progress);

      for (let p = 0; p < resp.paragraphs.length; p++) {
        let delta = 0;
        const respPar = resp.paragraphs[p];
        const paragraph = allParagraphs[respPar.parIndex];
        const brackets: boolean =
          respPar.parIndex >= allParagraphs.length - claimsParagraphsCount;

        for (let s = 0; s < respPar.searchResults.length; s++) {
          const refId = ares.references[respPar.searchResults[s].keyword];
          const charsCnt = await insertBookmarkReference(
            paragraph.uniqueLocalId,
            respPar.searchResults[s].startIndex + delta,
            respPar.searchResults[s].endIndex + delta,
            refId,
            brackets
          );
          delta += charsCnt;
        }

        setTotalProgressInBulk(
          promiseStatus,
          60 + p * (40 / resp.paragraphs.length)
        );
        statusManager.setProgress(promiseStatus.progress);
      }

      await insertBlockParagraphs(
        'references',
        ares.refSummary,
        false,
        true,
        true
      );

      setTotalProgressInBulk(promiseStatus, 100);
      statusManager.setProgress(promiseStatus.progress);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.references_inserted);
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

const applyAbstracts = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.generating_abstracts);

  let error = '';
  let httpCode = 0;
  const instructionType = 'abstracts';
  const patent_numbers: string[] = [];
  const abstract_text: string[] = [];

  if (!promiseStatus.aborted) {
    const localStorage = getLocalStorage();

    console.log(localStorage.abstracts);

    // Create an array with figures with both their name and description input by the user.
    for (let i = 0; i < localStorage.abstracts.length; i++) {
      // Concatenate with a Tab character between title and description
      let item = localStorage.abstracts[i].title;
      patent_numbers.push(item);
    }

    const resp = await fetchCommon(
      patent_numbers.join(' '),
      instructionType,
      'gpt',
      false
    );
    if (!resp.error) {
      abstract_text.push(resp.data);
      statusManager.setProgress(75);
    } else {
      error = resp.error;
      httpCode = resp.httpCode;
    }

    if (!error) {
      // First delete the whole figure block, then insert both the descriptions and the fetched figure text
      await insertBlockParagraphs('intro', abstract_text, true, false, true);

      statusManager.setProgress(100);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.abstract_generated);
    } else {
      if (resp.httpCode === 401) {
        statusManager.setStatusCode(StatusCodes.Unauthorized);
        statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.generation_failed);
      }
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

const refreshFigures = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.updating_figures);

  if (!promiseStatus.aborted) {
    await reloadClaimsToLocalStorage();
    statusManager.setProgress(100);
    statusManager.setStatusCode(StatusCodes.Success);
    statusManager.setStatusMessage(UIStrings.statusbar.figures_updated);
    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

const generateFigureDescription = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.generating_figures);

  let error = '';
  let httpCode = 0;
  const figure_descriptions: string[] = [];
  const figures: string[] = [];

  if (!promiseStatus.aborted) {
    const localStorage = getLocalStorage();

    // Create an array with figures with both their name and description input by the user.
    for (let i = 0; i < localStorage.figures.length; i++) {
      let item = localStorage.figures[i].title + '\t';
      // Remove trailing "." from figures[i].description if they exist
      const description = localStorage.figures[i].description.replace(
        /\.$/,
        ''
      );

      if (i === localStorage.figures.length - 1 && i > 0) {
        // Last element and more than 1 element, add "." at the end
        item += description + '.';
      } else if (i === localStorage.figures.length - 2 && i > 0) {
        // Second to last element and more than 1 element, add ", und" at the end
        item += description + ', und';
      } else {
        // For other elements, add "," at the end
        item += description + ',';
      }

      figure_descriptions.push(item);
    }

    for (let i = 0; i < localStorage.figures.length; i++) {
      const figureClaims: string[] = [];
      const ids = localStorage.figures[i].claimIds;
      for (let c = 0; c < ids.length; c++) {
        figureClaims.push(localStorage.claims[ids[c]]);
      }
      const resp = await fetchFigure(
        localStorage.figures[i].title,
        localStorage.figures[i].description,
        figureClaims
      );
      if (!resp.error) {
        figures.push(resp.data);
        statusManager.setProgress(((i + 1) * 90) / localStorage.figures.length);
      } else {
        error = resp.error;
        httpCode = resp.httpCode;
        break;
      }
    }

    if (!error) {
      // First delete the whole figure block, then insert both the descriptions and the fetched figure text
      await insertBlockParagraphs(
        'figure_descriptions',
        figure_descriptions,
        true,
        true,
        true
      );
      await insertBlockParagraphs('figures', figures, true, true, true);

      statusManager.setProgress(100);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.figures_generated);
    } else {
      if (httpCode === 401) {
        statusManager.setStatusCode(StatusCodes.Unauthorized);
        statusManager.setStatusMessage(UIStrings.statusbar.unauthorized);
      } else {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(UIStrings.statusbar.generation_failed);
      }
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

const finalizeDocumentConsistency = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.finalizing);

  if (!promiseStatus.aborted) {
    await deleteReferenceMarkers();
    await deleteErrorMarkers();

    await deleteBlocksMarkupParagraphs();

    statusManager.setProgress(100);
    statusManager.setStatusCode(StatusCodes.Success);
    statusManager.setStatusMessage(UIStrings.statusbar.finalized);

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

const generateReport = async (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus,
  resolve
) => {
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.generating_report);

  if (!promiseStatus.aborted) {
    const localStorage: localStorageType = getLocalStorage();
    const consistency: consistencyType[] = localStorage.consistency;
    const dateTime = new Intl.DateTimeFormat(tempStorage.apiLanguage, {
      dateStyle: 'medium',
      timeStyle: 'long',
    }).format(new Date());
    console.log(dateTime);

    const resp = await fetchGenerateReport(
      consistency,
      tempStorage.patentTitle,
      dateTime
    );

    if (!resp.error) {
      statusManager.setProgress(100);
      statusManager.setStatusCode(StatusCodes.Success);
      statusManager.setStatusMessage(UIStrings.statusbar.report_generated);
      tempStorage.reportId = resp.reportId;
    } else {
      statusManager.setProgress(100);
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(UIStrings.statusbar.generation_failed);
    }

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

const collectClaimsMap = async (claimsParagraphs: Word.Paragraph[]) => {
  let currentClaimId = -1;
  let currentClaim = '';
  const idx: number[] = [];
  const claims: string[] = [];

  for (const [, para] of Object.entries(claimsParagraphs)) {
    let paraText = await getParagraphTextWithoutReferences(para.uniqueLocalId);
    const numDotIdx = paraText.indexOf('.');
    if (numDotIdx > 0 && numDotIdx < 3) {
      const claimId = Number(paraText.substring(0, numDotIdx));
      if (!Number.isNaN(claimId)) {
        // Is first line of any claim.
        if (currentClaim) {
          // Push previous claim.
          claims.push(currentClaim);
          idx.push(currentClaimId);
        }
        currentClaimId = claimId;
        currentClaim = '';
      }
      paraText = paraText.substring(numDotIdx + 1);
    }

    if (currentClaimId >= 0) {
      let str = paraText;
      if (str.charAt(0) == '·') {
        str = str.substring(1, str.length);
      }
      str = str.replace(/[\r\n]+/g, ' ');
      str = str.trim() + ' ';

      currentClaim += str;
    }
  }

  if (currentClaim) {
    // Push previous claim.
    claims.push(currentClaim);
    idx.push(currentClaimId);
  }

  return { idx: idx, claims: claims };
};

const reloadClaimsToLocalStorage = async () => {
  const claimsParagraphs = await getMultiBlocksParagraphs(['claims']);
  const claimsMap = await collectClaimsMap(claimsParagraphs);
  updateClaims(claimsMap.claims);

  return claimsMap;
};

const findAllIndices = (text: string, subs: string) => {
  const a: number[] = [];
  let i = -1;
  while ((i = text.indexOf(subs, i + 1)) >= 0) a.push(i);
  return a;
};

export {
  getDefaultPromiseStatus,
  setTotalProgressInBulk,
  getStatusMessageInBulk,
  fillAllClaimsDescriptions,
  fillZone,
  collectClaimsMap,
  saveDefinitionInDb,
  refreshDefinitions,
  checkDefinition,
  uncheckDefinition,
  checkMultipleDefinitions,
  uncheckMultipleDefinitions,
  refreshReferences,
  applyReferences,
  applyAbstracts,
  generateFigureDescription,
  refreshFigures,
  finalizeDocumentConsistency,
  reloadClaimsToLocalStorage,
  generateReport,
  updateRoots,
  analyzeReferences,
  findAllIndices,
};
